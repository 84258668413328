<template>
  <section class="pad-t-0">
    <div class="bb-spinner" v-show="parsing">
      <div class="bb-spiner__content">
        <div class="bb-spinner__dots">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="bb-spinner__title">{{ $t("freeAdd.loadingMessage") }}</div>
        <div class="bb-spinner__p">
          {{ $t("freeAdd.loadingMessageAdv") }} <br />{{ $t("freeAdd.cancel1") }}
          <a @click.prevent="cancel()">{{ $t("freeAdd.cancel2") }}</a>
        </div>
      </div>
    </div>
    <div v-show="!parsing">
      <div v-if="!error" class="bb-container">
        <div v-if="!parsed" class="back">
          <div class="back__back">
            <a @click.prevent="$router.push({ name: 'list-constructor' })">
              <div class="back__name">
                <img
                  src="../../assets/img/ico/ico-arrow-back.svg"
                  loading="lazy"
                  alt="Volver"
                  class="back__arrow"
                />
              </div>
            </a>
          </div>
        </div>
        <div v-else>
          <div v-if="parsedIncomplete" class="alert alert--primary">
            <div class="alert__ico">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12.38 7.08C12.1365 6.97998 11.8635 6.97998 11.62 7.08C11.4973 7.12759 11.3851 7.19896 11.29 7.29C11.2017 7.3872 11.1306 7.49882 11.08 7.62C11.024 7.73868 10.9966 7.86882 11 8C10.9992 8.13161 11.0245 8.26207 11.0742 8.38391C11.124 8.50574 11.1973 8.61656 11.29 8.71C11.3872 8.79833 11.4988 8.86936 11.62 8.92C11.7715 8.98224 11.936 9.00632 12.099 8.99011C12.2619 8.97391 12.4184 8.91792 12.5547 8.82707C12.691 8.73622 12.8029 8.61328 12.8805 8.46907C12.9582 8.32486 12.9992 8.16378 13 8C12.9963 7.73523 12.8927 7.48163 12.71 7.29C12.6149 7.19896 12.5028 7.12759 12.38 7.08ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                  fill="#0C5460"
                />
              </svg>
            </div>
            <div class="alert__content">
              {{ $t("freeAdd.noticeIncomplete") }}
            </div>
            <span class="alert__close" @click="parsed = false">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z"
                  fill="#004085"
                />
              </svg>
            </span>
          </div>
          <div v-else class="alert alert--primary">
            <div class="alert__ico">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V12C13 11.7348 12.8946 11.4804 12.7071 11.2929C12.5196 11.1054 12.2652 11 12 11ZM12.38 7.08C12.1365 6.97998 11.8635 6.97998 11.62 7.08C11.4973 7.12759 11.3851 7.19896 11.29 7.29C11.2017 7.3872 11.1306 7.49882 11.08 7.62C11.024 7.73868 10.9966 7.86882 11 8C10.9992 8.13161 11.0245 8.26207 11.0742 8.38391C11.124 8.50574 11.1973 8.61656 11.29 8.71C11.3872 8.79833 11.4988 8.86936 11.62 8.92C11.7715 8.98224 11.936 9.00632 12.099 8.99011C12.2619 8.97391 12.4184 8.91792 12.5547 8.82707C12.691 8.73622 12.8029 8.61328 12.8805 8.46907C12.9582 8.32486 12.9992 8.16378 13 8C12.9963 7.73523 12.8927 7.48163 12.71 7.29C12.6149 7.19896 12.5028 7.12759 12.38 7.08ZM12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20Z"
                  fill="#0C5460"
                />
              </svg>
            </div>
            <div class="toast__content">
              {{ $t("freeAdd.noticeSuccess") }}
            </div>
            <span class="alert__close" @click="parsed = false">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.6979 6L12 10.3021L10.3021 12L6 7.6979L1.6979 12L0 10.3021L4.3021 6L0 1.6979L1.6979 0L6 4.3021L10.3021 0L12 1.6979L7.6979 6Z"
                  fill="#004085"
                />
              </svg>
            </span>
          </div>
        </div>

        <div class="g-edit">
          <div class="g-edit__header">
            <h1 class="g-edit__title">{{ $t("freeAdd.addProduct") }}</h1>
            <div class="g-edit__musthave">
              <must-have-toggle-intern
                :product="{ mustHave: mustHave }"
                @markAsMustHave="mustHave = true"
                @markAsnotMustHave="mustHave = false"
              />
            </div>
          </div>
          <div class="g-edit__cols">
            <div class="g-edit__picture">
              <h3 class="g-edit__head">{{ $t("productPage.productImage") }}</h3>
              <div class="g-edit__head-info">
                {{ $t("freeAdd.selectIdentifyingPicture") }}
              </div>

              <div class="add-free-picture">
                <div class="add-free-picture__items">
                  <no-ssr>
                    <VueSlickCarousel
                      v-if="showSlider"
                      v-bind="settings"
                      ref="carousel"
                      :key="$route.fullPath"
                    >
                      <div
                        v-for="(image, index) in images"
                        @click="selectImage(image)"
                        :key="index"
                      >
                        <div
                          class="add-free-picture__item"
                          :class="{ selected: imageUrl === image }"
                        >
                          <div class="add-free-picture__overlay"></div>
                          <span
                            class="add-free-picture__delete"
                            @click.prevent.stop="removeImage(image)"
                          >
                            <img
                              src="../../assets/img/ico/ico-delete-circle.svg"
                              alt=""
                            />
                          </span>
                          <img
                            :src="image"
                            loading="lazy"
                            width="100%"
                            height="auto"
                            class="add-free-picture__img"
                          />
                        </div>
                      </div>
                      <template #prevArrow="arrowOption">
                        <div class="slick-arrow--back">
                          {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                          <img
                            src="../../assets/img/ico/ico-arrow-slick-left.svg"
                            alt=""
                          />
                        </div>
                      </template>
                      <template #nextArrow="arrowOption">
                        <div class="slick-arrow--next">
                          {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
                          <img
                            src="../../assets/img/ico/ico-arrow-slick-left.svg"
                            alt=""
                          />
                        </div>
                      </template>
                    </VueSlickCarousel>
                  </no-ssr>
                </div>
              </div>
              <no-ssr>
                <div class="g-edit__upload">
                  <div
                    class="g-edit__upload-error"
                    v-if="this.images && this.images.length > 12"
                  >
                    {{ $t("productPage.notMoreThan12") }} <br />
                    {{ $t("productPage.removeToUploadMore") }}
                  </div>

                  <div class="g-edit__upload-input">
                    <input
                      type="file"
                      @change="previewAddedImage($event)"
                      name="uploadfile"
                      id="img"
                      style="display: none"
                      accept="image/*"
                    />
                    <label class="button button--dark-outline button--block" for="img"
                      >{{ $t("productPage.uploadImage") }}</label
                    >
                    <div class="bb-form-error" v-if="errorMsg">
                      {{ this.errorMsg }}
                    </div>
                    <p v-else>{{ $t("generic.photoMaxSize") }}</p>
                  </div>
                </div>
              </no-ssr>
            </div>
            <div class="g-edit__content">
              <must-have-toggle-intern :product="{ mustHave: mustHave }" />

              <h3 class="g-edit__head header-4">{{ $t("productPage.productData") }}</h3>

              <div class="edit-data">
                <div class="edit-data__item">
                  <div class="bb-form-item">
                    <div class="bb-form-label">{{ $t("generic.name") }}*</div>
                    <input
                      v-model="name"
                      class="bb-form"
                      type="text"
                      maxlength="256"
                      name="name"
                      id="name"
                      required=""
                      @blur="nameBlur"
                    />
                    <div class="bb-form-error" v-show="nameHasError">
                      {{ $t("freeAdd.nameRequired") }}
                    </div>
                  </div>
                </div>
                <div class="edit-data__item">
                  <div class="bb-form-item">
                    <div class="bb-form-label">Url</div>

                    <div class="bb-form-group">
                      <input
                        v-model="url"
                        class="bb-form"
                        type="text"
                        maxlength="256"
                        name="url"
                        id="url"
                        required=""
                        @blur="urlBlur"
                        :disabled="urlFromParser !== ''"
                      />
                      <a :href="url" target="_blank" class="bb-form-group__ico">
                        <img src="../../assets/img/ico/ico-external-link.svg" />
                      </a>
                    </div>

                    <div class="bb-form-error" v-show="urlHasError">
                      {{ $t("freeAdd.urlOK") }}
                    </div>
                  </div>
                </div>
                <div class="edit-data__item">
                  <div class="bb-form-item">
                    <div class="bb-form-label">{{ $t("generic.price") }}*</div>
                    <input
                      v-model="price"
                      class="bb-form"
                      type="number"
                      maxlength="256"
                      name="price"
                      id="price"
                      required=""
                      @blur="priceBlur"
                    />
                    <div class="bb-form-error" v-show="priceHasError">
                      {{ $t("freeAdd.priceRequired") }}
                    </div>
                  </div>
                </div>
                <div class="edit-data__item">
                  <div class="bb-form-item">
                    <div class="bb-form-label">{{ $t("generic.category") }}*</div>

                    <div
                      @blur="categoryDirty = true"
                      tabindex="0"
                      class="bb-filter bb-filter--block"
                      v-click-outside="config"
                    >
                      <div
                        class="bb-filter__select bb-filter__select--block bb-filter__select--form"
                        @click="toggle"
                        :class="toggleCategories ? 'bb-filter__select--active' : ''"
                      >
                        {{ categoryLabel }}
                        <img
                          src="../../assets/img/ico/ico-chevron-up.svg"
                          loading="lazy"
                          alt=""
                          width="16px"
                          :class="
                            toggleCategories
                              ? 'bb-filter__chevron bb-filter__chevron--active'
                              : 'bb-filter__chevron '
                          "
                        />
                      </div>
                      <div class="bb-filter__items" v-show="toggleCategories">
                        <div
                          class="bb-filter__item bb-filter__item--option"
                          v-for="(category, index) in translatedCategories"
                          :key="index"
                          @click="applyCategory(category)"
                          :class="
                            category.id === categorySelected
                              ? 'bb-filter__item--active'
                              : ''
                          "
                        >
                          <span v-text="category.name" />
                        </div>
                      </div>
                    </div>

                    <div class="bb-form-error" v-show="categoryHasError">
                      {{ $t("freeAdd.categoryRequired") }}
                    </div>
                  </div>
                </div>
                <div class="edit-data__item">
                  <div class="bb-form-item">
                    <div class="bb-form-label">{{ $t("productPage.comments") }}</div>
                    <textarea
                      v-model="userComments"
                      class="bb-form-textarea"
                      type="text"
                      maxlength="256"
                      row="4"
                      name="userComments"
                      id="userComments"
                      required=""
                    />
                  </div>
                </div>
              </div>

              <div class="g-edit__actions-column">
                <loading-button v-if="saving"></loading-button>
                <button
                  v-else
                  class="button button--primary button--block"
                  @click.prevent="save"
                  :disabled="disabled"
                >
                {{ $t("freeAdd.addToTheList") }}
                </button>

                <a @click.prevent="$router.push({ name: 'list-constructor' })">
                  <button class="button button--secondary button--block">{{ $t("generic.cancel") }}</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-dialog
      :show="showModalProductAdded"
      @close="close()"
      target-class="modal__card--nopadding"
    >
      <FirstProductAddedToList v-if="isFirstProduct" @close="close()" />
      <product-added-to-list v-else />
    </modal-dialog>
  </section>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import { mapGetters } from "vuex";
import LoadingButton from "../../components/LoadingButton";
import vClickOutside from "v-click-outside";
import CanGetOwnerList from "../../Mixins/CanGetOwnerList";
import ModalDialog from "../../components/ui/ModalDialog";
import MustHaveToggleIntern from "../ownerlist/components/MustHaveToggleIntern";
import ProductAddedToList from "../../components/ProductAddedToList";
import NoSSR from "vue-no-ssr";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import RemoveImage from "../ownerlist/Mixins/RemoveImage";
import SelectImage from "../ownerlist/Mixins/SelectImage";
import PreviewPhoto from "../ownerlist/Mixins/PreviewPhoto";
import CanGetCategories from "../../Mixins/CanGetCategories";
import FirstProductAddedToList from "./components/FirstProductAddedToList";

export default {
  name: "AddProductPage",
  mixins: [
    CanGetOwnerList,
    RemoveImage,
    SelectImage,
    PreviewPhoto,
    CanGetCategories,
  ],
  components: {
    VueSlickCarousel,
    MustHaveToggleIntern,
    LoadingButton,
    ModalDialog,
    ProductAddedToList,
    FirstProductAddedToList,
    "no-ssr": NoSSR,
  },
  metaInfo() {
    return {
      title: `Añadir objeto | HelloBB`,
    };
  },
  data() {
    return {
      isFirstProduct: false,
      preview: false,
      errorMsg: null,
      showSlider: false,
      mustHave: false,
      showModalProductAdded: false,
      error: false,
      parsing: true,
      parsed: false,
      parsedIncomplete: false,
      urlFromParser: "",
      name: "",
      nameDirty: false,
      url: "",
      imageUrl: "",
      urlDirty: false,
      price: "",
      priceDirty: false,
      userComments: "",
      categorySelected: null,
      categoryDirty: false,
      saving: false,
      config: {
        handler: this.closeCategories,
        events: ["dblclick", "click"],
      },
      toggleCategories: false,
      productPhotos: [],
      productUploadedPhotos: [],
      currentIndex: 0,
      settings: {
        draggable: false,
        centerMode: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 3,
        arrows: true,
        infinite: false,
        slidesPerRow: 1,
        responsive: [
          {
            breakpoint: 767,
            settings: {
              centerMode: true,
              rows: 1,
              slidesToShow: 1,
              slidesToScroll: 2,
              // slidesPerRow: 1,
              infinite: false,
              dots: true,
              arrows: false,
            },
          },
        ],
      },
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  computed: {
    ...mapGetters({
      categories: "categories",
      listActive: "listActive",
    }),
    defaultPhotoIndex() {
      let x = this.productPhotos.indexOf(this.imageUrl);
      if (x === -1) {
        return (
          this.productPhotos.length +
          this.productUploadedPhotos.indexOf(this.imageUrl) +
          1
        );
      }
      return x + 1;
    },
    images() {
      return this.$utils.removeDuplicates([
        ...this.productUploadedPhotos,
        ...this.productPhotos,
      ]);
    },
    disabled() {
      if (
        this.name !== "" &&
        this.url !== "" &&
        this.price !== "" &&
        this.price !== "-1" &&
        !isNaN(this.price.replace(",", "."))
      ) {
        return false;
      }
      return true;
    },
    categoryLabel() {
      const category = this.categories.find((category) => category.id === this.categorySelected);
      let translatedName = "";
      const categoryId = category?.id;
      if (categoryId) {
        translatedName = this.getTranslatedCategory(categoryId);
      }
      return translatedName || category?.name || "";
    },
    nameHasError() {
      return this.name === "" && this.nameDirty;
    },
    priceHasError() {
      return (this.price == "" || this.price == -1) && this.priceDirty;
    },
    urlHasError() {
      return this.url === "" && this.urlDirty;
    },
    categoryHasError() {
      return this.categorySelected === null && this.categoryDirty;
    },
    translatedCategories() {
      return this.categories.map((category) => ({
        ...category,

        name: this.getTranslatedCategory(category.id) || category.name,
      }));
    },
  },
  methods: {
    previewAddedImage(event) {
      this.previewPhoto(event);
      this.$refs.carousel.goTo(0);
    },
    cancel() {
      this.parsing = false;
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrevious() {
      this.$refs.carousel.prev();
    },
    async save() {
      if (this.categorySelected === null) {
        this.categoryDirty = true;
        return;
      }
      this.saving = true;
      try {
        const product = {
          name: this.name,
          url: (this.urlFromParser || this.url),
          price: parseFloat(this.price.replace(",", ".")),
          mainCategoryId: this.categorySelected,
          productPhotos: this.productPhotos,
          productUploadedPhotos: this.productUploadedPhotos.map((photo) =>
            photo
              .replace("data:image/png;base64,", "")
              .replace("data:image/jpeg;base64,", "")
          ),
          defaultPhotoIndex: this.defaultPhotoIndex,
          info: this.userComments,
          mustHave: this.mustHave,
        };
        await this.$store.dispatch("addProduct", { list: this.list, product: product });
        await this.getList();
      } catch (error) {
        console.log(error);
        this.saving = false;
        return;
      }
      window.mixpanel.track("Afegir objecte", {
        "Item Category": this.categories.find(
          (category) => category.id === this.categorySelected
        ).name,
        "Item name": this.name,
        "Item Price": this.price,
        "Add Platform": this.$getPlatform(),
        "Add client": this.$store.state.cookies.sourceApp == "true" ? "App" : "Webapp",
        "Add type": "Objecte lliure",
        Source: "Constructor",
        "Item retailer": this.$getRetailer(this.url),
        "Item URL": this.url,
      });
      window.mixpanel.people.increment("Total Add");
      window.mixpanel.people.increment("List # elements");
      window.mixpanel.people.increment("List value", this.price);
      window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
      window.mixpanel.people.set_once({ "First Add": new Date().toISOString() });
      this.url = "";
      this.name = "";
      this.userComments = "";
      this.imageUrl = "";
      this.mainCategoryId = "";
      this.price = "";
      this.$emit("productAdded");
      this.saving = false;
      this.showModalProductAdded = true;
    },
    async parse() {
      var bodyObject = {
        userid: "366",
        parsedfrom: "link-web",
      };
      this.parsing = true;

      let data;
      try {
        this.url = this.$route.query.url;
        data = await this.$parseUrl(this.$route.query.url, {
          method: "POST",
          body: bodyObject,
        });
      } catch (error) {
        console.log(error);
        this.error = true;
        this.parsing = false;
        this.$store.commit("setParserError", true);
        this.$store.commit("setParserErrorUrl", this.$route.query.url);
        this.$router.push({
          name: "list-constructor",
          params: {
            error: true,
            message: this.$t("freeAdd.notAbleToLoadLink"),
          },
        });
        return;
      }

      if (this.parsing) {
        if (data.Amount) this.price = String(data.Amount);
        if (data.Title) this.name = data.Title;
        if (data.Images) {
          this.productPhotos = data.Images;
          this.imageUrl = this.productPhotos[0];
          if (this.images.length > 0) this.showSlider = true;
        }
        if (data.Url) {
          this.urlFromParser = data.Url;
          this.url = this.urlFromParser;
        } else {
          this.url = this.$route.query.url;
        }

        this.parsed = true;
        this.checkParsing();
      }

      this.parsing = false;
    },
    checkParsing() {
      if (this.price == -1 || this.price == "") {
        this.parsedIncomplete = true;
        this.priceDirty = true;
      }
      if (this.name == "") {
        this.parsedIncomplete = true;
        this.nameDirty = true;
      }
    },
    close() {
      this.showModalProductAdded = false;
      this.$router.push({ name: "list-constructor" });
    },
    toggle() {
      this.toggleCategories = !this.toggleCategories;
    },
    applyCategory(category) {
      this.categorySelected = category.id;
      this.toggleCategories = false;
    },
    closeCategories() {
      this.toggleCategories = false;
    },
    nameBlur() {
      this.nameDirty = true;
    },
    urlBlur() {
      this.urlDirty = true;
    },
    priceBlur() {
      this.priceDirty = true;
    },
    getList() {
      return this.$store.dispatch("getOwnerList");
    },
  },
  async serverPrefetch() {
    return await this.getList();
  },
  async mounted() {
    await this.parse();
    if (!this.error) {
      await this.$store.dispatch("getCategories");
    }

    if (!this.listActive) {
      // Only set the flag once when the component is mounted.
      //
      // We're only interested in the value of this flag at the moment the page
      // loaded, so we don't want this value to change if `listActive` changes
      // after the page loaded.
      this.isFirstProduct = true;
    }
  },
};
</script>

<style scoped>
.slick-next::before {
  color: black;
}

.slick-prev::before {
  color: black;
}
.circle-imatge {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #ffbd59;
}

.circle-imatge.gris {
  width: 12px;
  height: 12px;
  margin-right: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  border-radius: 60px;
  background-color: #dcdcdc;
}

.circle-imatge.gris.darrer {
  margin-right: 0px;
}
</style>
